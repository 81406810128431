import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StyledTenantDetailMetaHeaderText, StyledTenantDetailsMetaWrapper } from '../TenantDetailsSideArea.styles';
import ServiceLink from './ServiceLink';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAuthentication } from '../../../../../common/hooks/authentication';
var getServicesShowMap = function (tenantRole, tags) {
    var showMap = {
        'pricer-admin': ['central manager', 'app manager', 'store', 'iam'],
        'pricer-editor': ['store', 'iam', 'central manager'],
        'pricer-viewer': ['store', 'central manager'],
        'pricer-profile': ['store'],
        'tenant-admin': ['iam', 'plaza home', 'central manager'],
        'business-admin': ['iam', 'plaza home', 'central manager'],
        'store-manager': ['plaza home', 'central manager'],
        'store-worker': ['plaza home', 'central manager'],
        installer: ['plaza home', 'central manager'],
        designer: ['plaza home', 'central manager'],
        metrics: ['plaza home', 'central manager']
    };
    var tagRoles = tags ? Object.values(tags) : [];
    var role = tagRoles.length > 0 && tenantRole === 'pricer-profile'
        ? tagRoles[0]
        : tenantRole;
    return showMap[role];
};
export default function Services(_a) {
    var services = _a.services;
    var _b = useAuthentication(), tenantRole = _b.role, tags = _b.tags;
    var tenantId = useParams().id;
    return (_jsxs("div", { style: { padding: '15px' }, children: [_jsx(StyledTenantDetailsMetaWrapper, { children: _jsx(StyledTenantDetailMetaHeaderText, { children: "Services" }) }), _jsx(Grid, { container: true, spacing: 1, alignItems: "center", children: services.map(function (service, id) { return (_jsx(ServiceLink, { servicesShowList: getServicesShowMap(tenantRole, tags), service: service, tenantId: tenantId }, id)); }) })] }));
}
